import { faSquareFacebook, faSquareInstagram } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function Footer() {
  return (
    <footer>
      <section>
        <div className="container">
          <div className="grid lg:grid-cols-4 gap-4">
            <div className="col-span-2">
              <h1>EDUDOKO</h1>
              <ul className="flex gap-3">
                <li><FontAwesomeIcon icon={faSquareFacebook} className="fa-xl" /></li>
                <li><FontAwesomeIcon icon={faSquareInstagram} className="fa-xl" /></li>
                <li><FontAwesomeIcon icon={faEnvelope} className="fa-xl" /></li>
              </ul>
            </div>
            <div>
              <h3 className="mb-5">Get Help</h3>
              <ul>
                <li className="font-semibold py-1">FAQ's</li>
                <li className="font-semibold py-1">Customer services</li>
                <li className="font-semibold py-1">How to guide</li>
                <li className="font-semibold py-1">Contact us</li>
              </ul>
            </div>
            <div>
              <h3 className="mb-5">Other</h3>
              <ul>
                <li className="font-semibold py-1">Privacy policy</li>
                <li className="font-semibold py-1">Location</li>
                <li className="font-semibold py-1">Subscriptions</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
}
