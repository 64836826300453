import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import publicStore from "../../../stores/publicStore";
import { faLandmark, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BasicPagination from "../../../components/BasicPagination";
import { CryptoJSObjectEncrypt, encryptId, toastError } from "../../../utils/helper";
import { webRoutes } from "../../../routes/web";
import CompareCourse from "./compareCourse";

export default function SearchCourses() {
  const { state } = useLocation();
  const { country, course, degree, institute } = state ?? {};

  const { courses, fetchCourses } = publicStore();

  const [limit, setlimit] = useState(12);
  const [offset, setOffset] = useState(0);
  const [compareCourse, setCompareCourse] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const params = new URLSearchParams();
  if (course) params.append("course_category", course);
  if (degree) params.append("degree", degree);
  if (country) params.append("institute__country", country);
  if (institute) params.append("institute", institute);

  params.append("is_active", true);
  params.append("order", "title");
  params.append("limit", limit);
  params.append("offset", offset);

  useEffect(() => {
    fetchCourses(params.toString());
  }, [offset, limit, state]);

  const setDefaultCurrent = (val) => setOffset(val * limit - limit);
  const setpageSizeOptions = (val) => setlimit(val);

  const storeCompareCourse = (id) => {
    let compareCourseArray = [...compareCourse];
    if (compareCourseArray.length < 6) {
      compareCourseArray.push({
        id: id,
      });
      setCompareCourse(compareCourseArray);
    } else {
      toastError("Compare limit reached.")
    }
  };

  const showCompareButton = (id) => {
    return compareCourse.find((item) => item.id === id) ? true : false;
  };

  const removeCompareCourse = (id) => {
    let compareCourseArray = [...compareCourse];
    compareCourseArray = compareCourseArray.filter((item) => item.id !== id);
    setCompareCourse(compareCourseArray);
  };

  return (
    <div className="relative">
      <section className="container">
        <div className="grid lg:grid-cols-3 gap-4">
          {courses.results &&
            courses.results.map((course) => {
              return (
                <div className="bg-theme-primary text-white rounded-lg overflow-hidden relative shadow-xl">
                  <Link
                    to={
                      webRoutes.courseDetails +
                      "?course=" +
                      encryptId(course.id)
                    }
                  >
                    <img
                      src={course?.institute?.logo}
                      alt=""
                      className="h-52 w-full object-fill"
                    />
                  </Link>

                  <span className="py-1 px-2 text-xs font-semibold bg-theme-primary rounded absolute top-2 left-2">
                    {course.degree.name}
                  </span>
                  <div className="p-3">
                    <p className="bg-white py-2 px-3 text-black rounded mb-3 font-semibold">
                      {course.title}
                    </p>
                    <div className="flex">
                      <FontAwesomeIcon
                        icon={faLandmark}
                        className="me-2 mt-1"
                        style={{ width: "24px" }}
                      />
                      <p className="mb-2">{course?.institute?.title}</p>
                    </div>
                    <div className="flex mb-9">
                      <FontAwesomeIcon
                        icon={faLocationDot}
                        className="me-2 mt-1"
                        style={{ width: "24px" }}
                      />
                      <p>{course?.institute?.address}</p>
                    </div>
                    <div className="flex justify-between absolute bottom-3 left-3 right-3">
                      {!showCompareButton(course.id) && (
                        <span
                          className="text-black py-1 px-2 text-xs font-semibold bg-white rounded cursor-pointer"
                          onClick={() => storeCompareCourse(course.id)}
                        >
                          Compare
                        </span>
                      )}
                      {showCompareButton(course.id) && (
                        <span
                          className="text-red-50 py-1 px-2 text-xs font-semibold bg-red-500 rounded cursor-pointer"
                          onClick={() => removeCompareCourse(course.id)}
                        >
                          Remove Compare
                        </span>
                      )}
                      {/* <Link to={CryptoJSObjectEncrypt(course.id)}>
                      <span className="text-black py-1 px-2 text-xs font-semibold bg-white rounded">
                        View
                      </span>
                      </Link> */}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>

        <BasicPagination
          total={courses.count}
          limit={limit}
          defaultCurrent={setDefaultCurrent}
          pageSizeOptions={setpageSizeOptions}
        />
      </section>

      {compareCourse && compareCourse.length > 0 && (
        <div
          onClick={() => setIsModalOpen(true)}
          className="inline-block px-4 py-3 bg-theme-primary text-white rounded-full cursor-pointer shadow-xl fixed bottom-3 right-3"
        >
          View compare
        </div>
      )}

      <CompareCourse
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        compareCourse={compareCourse}
      />
    </div>
  );
}
