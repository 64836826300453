import { getAuth } from "../auth/AuthHelpers";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import axiosInstance from "../routes/axiosInstance";
import { apiList } from "../routes/api";
import { toast } from "react-toastify";

const CryptoJS = require("crypto-js");

const AUTH_LOCAL_ACCESS_TOKEN = "astd-auth-token";

// Set the expiration time to 5 minutes from the current date and time

export const encryptId = (id) => {
  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(id));
};

// Function to decrypt an encrypted ID
export const decryptId = (encryptedId) => {
  return CryptoJS.enc.Base64.parse(encryptedId).toString(CryptoJS.enc.Utf8);
};

export const CryptoJSObjectEncrypt = (data) => {
  return CryptoJS.AES.encrypt(
    JSON.stringify(data),
    process.env.REACT_APP_SECRET_KEY
  ).toString();
};

export const CryptoJSObjectDecrypt = (encryptedText) => {
  var bytes = CryptoJS.AES.decrypt(
    encryptedText,
    process.env.REACT_APP_SECRET_KEY
  );
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export const GetUserRole = () => {
  return getAuth()?.user?.role;
};

export const getUserToken = () => {
  return Cookies.get(AUTH_LOCAL_ACCESS_TOKEN) ?? getAuth()?.access;
};

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

let refreshTokenPromise = null;
let isRefreshing = false;

async function refreshToken() {
  // Check if a refresh token request is already in progress
  if (isRefreshing) {
    // Return the existing promise
    await sleep(1000);
    return refreshTokenPromise;
  }

  // Set the flag to true and create the refresh token promise
  isRefreshing = true;

  const access = getUserToken();
  const refresh = getAuth()?.refresh;

  const decoded = jwtDecode(access);
  const current_time = Math.floor(Date.now() / 1000);
  if (current_time >= decoded.exp) {
    const response = await axiosInstance.post(
      apiList.apiTokenRefresh,
      { refresh: refresh },
      {
        headers: {
          Authorization: "Bearer " + access,
        },
      }
    );

    const expiresInMinutes = 5;
    const now = new Date();
    const expires = new Date(now.getTime() + expiresInMinutes * 60 * 1000);
    Cookies.set(AUTH_LOCAL_ACCESS_TOKEN, response.data.access, {
      expires: expires,
    });
  }
  isRefreshing = false;
  refreshTokenPromise = null;
}

export const AuthRefreshToken = async () => {
  try {
    await refreshToken();
    // Use the new access token for further API calls
  } catch (error) {
    // Handle error
    console.error("Failed to refresh token:", error);
  }
};

export const toastSuccess = (message) => {
  toast.success(message, {
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    pauseOnFocusLoss: false,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

export const toastError = (message) => {
  toast.error(message, {
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    pauseOnFocusLoss: false,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};
