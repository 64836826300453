import React, { useEffect } from 'react'
import StudentList from '../student/StudentList'
import { useParams } from 'react-router-dom';
import userStore from '../../../stores/userStore';
import { Row, Col, Typography } from 'antd';

export default function CounsellorStudentList() {
    let { id } = useParams();
    const { Text, Title } = Typography;
    const { profile, getUserProfile } = userStore()
    console.log(profile);
    useEffect(() => {
        getUserProfile(id)
    }, [])


    return (
        <div>
            <h3 className='mb-3'>Counsellor Details</h3>
            <div className='grid grid-cols-1 mb-5 bg-white border rounded-lg shadow p-6'>
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <Text type="secondary">Full Name</Text>
                        <Title level={5} className="font-semibold">{profile?.first_name + " " + profile?.last_name}</Title>
                    </Col>
                    <Col span={8}>
                        <Text type="secondary">Phone No.</Text>
                        <Title level={5} className="font-semibold">{profile?.phone}</Title>
                    </Col>
                    <Col span={8}>
                        <Text type="secondary">DOB</Text>
                        <Title level={5} className="font-semibold">{profile?.dob}</Title>
                    </Col>
                    <Col span={8}>
                        <Text type="secondary">Email</Text>
                        <Title level={5} className="font-semibold">{profile?.email}</Title>
                    </Col>
                    <Col span={8}>
                        <Text type="secondary">Address</Text>
                        <Title level={5} className="font-semibold">{profile?.address}</Title>
                    </Col>
                    {profile?.role && (
                        <Col span={8}>
                            <Text type="secondary">Role</Text>
                            <Title level={5} className="font-semibold">{profile?.role}</Title>
                        </Col>
                    )}
                </Row>
            </div>

            <StudentList userId={id} mode="counsellor" />
        </div>
    )
}
