export const apiList = {
  countries: "countries/",
  degrees: "degrees/",
  courseCategories: "course-categories/",
  institutes: "institutes/",
  courses: "courses/",

  // User
  userProfile: "/user/profile/",
  userPassword: "user/password/",

  // Auth
  login: "auth/token/",
  apiTokenRefresh: "auth/token/refresh/",
  apiSignup: "signup/",
  apiForgetPassword: "password-reset/",
  apiPasswordResetConfirm: "password-reset-confirm/",

  // Admin and Super-admin
  adminAllUsers: "api-admin/users/",
  adminCreateUser: "api-admin/create-user/",
  adminStudentApplicationList: "api-admin/student-applications/",
  adminStudentAcademicDocuments: "api-admin/academic-documents/",
  degreesLists: "/api-admin/degrees/",
  countriesList: "/api-admin/countries/",
  coursesList: "/api-admin/courses/",
  courseCategoriesList: "/api-admin/course-categories/",
  institutesList: "/api-admin/institutes/",
  academicDocumentList: "/api-admin/academic-document-names/",
  uploadCSVCourses: "/api-admin/courses/upload-csv/",
  videoCourseCategoriesList: "/api-admin/video-course-categories/",
  adminVideoCoursesList: "/api-admin/video-courses/",
  adminVideoCourseLessonsList: "/api-admin/video_course_lessons/",

  // Student
  studentDocumentList: "student/my-academic-documents/",
  studentApplicationList: "student/my-applications/",
  studentAcademicDocuments: "student/my-academic-documents/",
  studentvideoCourseEnrollments: "student/video-course-enrollments/",

  // Agent
  agentStudentApplicationCreate: "agent/student-applications/",
  agentStudentList: "/agent/students/",
  agentStudentAcademicDocuments: "agent/academic-documents/",
  agentCreateStudent: "agent/create-student/",

  // counsellor
  counsellorStudentList: "counsellor/students/",
  counsellorAcademicDocuments: "counsellor/academic-documents/",
  counsellorStudentApplications: "counsellor/student-applications/",
  counsellorCreateStudent: "counsellor/create-student/",

  // document
  academicDocumentNames: "academic-document-names/",

  // vidoe
  videoCoursesList: "video-courses/",
  videoCourseLession: "video_course_lessons/",

  applicationStatuses: "application-statuses/",
  general_inquiries_create: "general-inquiries/",

  adminGeneralInquiries: "api-admin/general-inquiries/",

  postApplicationStatuses: "api-admin/application-statuses/",
};
