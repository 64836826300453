import { Button, DatePicker, Form, Input, Switch } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import authStore from "../../stores/authStore";
import { Constant } from "../../utils/constant";
import { GetUserRole, getUserToken, toastSuccess } from "../../utils/helper";
dayjs.extend(customParseFormat);
const dateFormat = "YYYY-MM-DD";

export default function EditRegisterComponent(props) {
  const [form] = Form.useForm();
  const { onCancel, fetchUserIfNecessary, mode, formData } = props;
  const { updateStudentFromAuth, updateCounsellorFromAuth } =
    authStore();

  const [DOB, setDOB] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Set form values whenever initialData changes
    form.setFieldsValue(formData);
  }, [formData]);

  const onChange = (date, dateString) => {
    // console.log("date: ", date);
    setDOB(dateString);
  };

  const onFinish = async (formValue) => {
    setLoading(true);
    console.log(formValue);
    debugger;
    if (GetUserRole()) {
      if (mode === Constant.student) {
        updateStudentFromAuth(formData.id, formValue)
          .then(() => {
            toastSuccess("User updated successfully!");
            onCancel();
            fetchUserIfNecessary();
          })
          .catch((onRejected) => {
            console.log(onRejected);
          })
          .finally(() => setLoading(false));
      }

      if (mode === Constant.counsellor) {
        updateCounsellorFromAuth(formData.id, formValue)
          .then(() => {
            toastSuccess("Counsellor updated successfully!");
            onCancel();
            fetchUserIfNecessary();
          })
          .catch((onRejected) => {
            console.log(onRejected);
          })
          .finally(() => setLoading(false));
      }

      if (mode === Constant.agent) {
        updateCounsellorFromAuth(formData.id, formValue)
          .then(() => {
            toastSuccess("Agent updated successfully!");
            onCancel();
            fetchUserIfNecessary();
          })
          .catch((onRejected) => {
            console.log(onRejected);
          })
          .finally(() => setLoading(false));
      }
    }
  };

  return (
    <Form
      layout={"vertical"}
      style={{
        maxWidth: 800,
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: '2rem'
      }}
      form={form}
      requiredMark={false}
      onFinish={onFinish}
      autoComplete="off"
      scrollToFirstError
      initialValues={formData}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">

        <Form.Item
          label="Email"
          name="email"
          className="required font-semibold"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            {
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <Input placeholder="Email" size="large" />
        </Form.Item>

        <Form.Item
          label="First Name"
          className="required font-semibold"
          name={"first_name"}
          rules={[
            {
              required: true,
              message: "Please input your First Name!",
            },
          ]}
        >
          <Input className="mb-2" size="large" />
        </Form.Item>

        <Form.Item
          label="Last Name"
          className="required font-semibold"
          name={"last_name"}
          rules={[
            {
              required: true,
              message: "Please input your Last Name!",
            },
          ]}
        >
          <Input className="mb-2" size="large" />
        </Form.Item>

        <Form.Item
          label="Phone No."
          className="required font-semibold"
          name={"phone"}
          rules={[
            {
              required: true,
              message: "Please input your Phone No.!",
            },
            {
              pattern: /^\d{10}$/,
              message: "Phone number must be exactly 10 digits!",
            },
          ]}
        >
          <Input className="mb-2 w-full" size="large" />
        </Form.Item>

        <Form.Item
          label="DOB"
          className="required font-semibold"
          name={"dob"}
          valuePropName={"date"}
          rules={[
            {
              required: true,
              message: "Please input your Date of Birth!",
            },
          ]}
        >
          <DatePicker
            onChange={onChange}
            size="large"
            className="w-full"
            defaultValue={dayjs(formData.dob, dateFormat)}
          />
        </Form.Item>

        <Form.Item label="Address" className="font-semibold" name={"address"}>
          <Input className="mb-2" size="large" />
        </Form.Item>

        <Form.Item
          label="Passport Number"
          className="font-semibold"
          name={"passport_number"}
        >
          <Input className="mb-2" size="large" />
        </Form.Item>

        <Form.Item
          label="consultancy"
          className="font-semibold"
          name={"consultancy"}
        >
          <Input className="mb-2" size="large" />
        </Form.Item>

        <Form.Item name="is_active" label="Status" className="font-semibold">
          <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
        </Form.Item>

      </div>
      <Form.Item className="mb-0">
        <Button
          type="primary"
          size="large"
          className="w-full"
          htmlType="submit"
          loading={loading}
        >
          Update
        </Button>
      </Form.Item>

      {!getUserToken() && (
        <p className="font-semibold text-sm text-center">
          Already have an account? <Link to={"#"}>Sign in here.</Link>
        </p>
      )}
    </Form>
  );
}
