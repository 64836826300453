import { Form, Input, message, Select, Upload } from "antd";
import React from "react";
import PostModal from "../../../components/PostModal";
import { InboxOutlined } from "@ant-design/icons";
const { Dragger } = Upload;

export default function DocumentsForm(props) {
  const { formData } = props;
  console.log(formData);
  const draggerProps = {
    name: "file",
    multiple: false,
    accept: "image/png, image/jpeg",
    maxCount: 1,
    beforeUpload: (file) => {
      return false;
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <>
      <PostModal {...props}>
        <Form.Item
          label="Name"
          name="title"
          className="required font-semibold"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Name" size="large" />
        </Form.Item>

        <Form.Item
          label="Order"
          name="order"
          className="required font-semibold"
          rules={[
            {
              required: true,
            },
            {
              pattern: /^[0-9]*$/, // Regular expression for numbers only
              message: "Please input a valid number for the order!",
            },
          ]}
        >
          <Input placeholder="Order" size="large" />
        </Form.Item>

        <Form.Item
          label="Group"
          name="group"
          className="required font-semibold"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            size="large"
            placeholder="Select a group"
            options={[
              {
                value: "bachelors",
                label: "bachelors",
              },
              {
                value: "school/high-school",
                label: "school/high-school",
              },
              {
                value: "masters",
                label: "masters",
              },
              {
                value: "other",
                label: "other",
              },
            ]}
          />
        </Form.Item>
      </PostModal>
    </>
  );
}
