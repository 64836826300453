// store.js
import { create } from "zustand";
import axiosInstance from "../routes/axiosInstance";
import { apiList } from "../routes/api";
import { AuthRefreshToken, GetUserRole, getUserToken } from "../utils/helper";
import { Constant } from "../utils/constant";

let url;
const agentStore = create((set) => ({
  agentList: [],

  getAllAgent: async (params) => {
    await AuthRefreshToken();
    switch (GetUserRole()) {
      case Constant.student:
        url = apiList.studentDocumentList;
        break;

      case Constant.agent:
        url = apiList.agentStudentAcademicDocuments;
        break;

      default:
        url = apiList.adminAllUsers;
        break;
    }

    const response = await axiosInstance.get(
      params ? url + "?role=agent&user=" + params : url + "?role=agent",
      {
        headers: {
          Authorization: "Bearer " + getUserToken(),
        },
      }
    );
    if (response.status === 200) {
      set({ agentList: response.data.results });
    }
  },
}));

export default agentStore;
