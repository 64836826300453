import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { webRoutes } from "../../routes/web";
import authStore from "../../stores/authStore";
import { useAuth } from "../../auth/Auth";
import { Button, Form, Input } from "antd";
import { GetUserRole, toastError } from "../../utils/helper";
import { Constant } from "../../utils/constant";

export default function LoginPage() {
  const navigate = useNavigate();
  const { saveAuth, setCurrentUser } = useAuth();

  const { login } = authStore();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    const response = await login(values);
    if (response?.user) {
      saveAuth(response);
      setCurrentUser(response?.user);

      switch (GetUserRole()) {
        case Constant.student:
          navigate(webRoutes.userProfile);
          break;

        default:
          navigate(webRoutes.dashboard);
          break;
      }
    } else {
      setLoading(false);
      toastError("No active account found with the given credentials.");
    }
  };

  return (
    <section className="h-full lg:h-screen flex items-center justify-center">
      <div className="container">
        <Form
          layout={"vertical"}
          className="border rounded-lg shadow-lg"
          style={{
            maxWidth: 500,
            marginLeft: "auto",
            marginRight: "auto",
            padding: '2rem',
          }}
          requiredMark={false}
          onFinish={onFinish}
          autoComplete="off"
        >
          <h2 className="mb-3">Sign in</h2>

          <Form.Item
            label="Email"
            name="username"
            className="required font-semibold"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Enter your email" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            className="required font-semibold"
            rules={[
              {
                required: true,
              },
            ]}
            style={{ marginBottom: 6 }}
          >
            <Input.Password placeholder="Enter your password"/>
          </Form.Item>

          <div className="mb-5 text-end">
            <Link to={webRoutes.forgetPassword} className="font-semibold text-sm mb-3 ">
              Forget password?
            </Link>
          </div>

          <Form.Item className="">
            <Button
              type="primary"
              size="large"
              className="w-full"
              htmlType="submit"
              loading={loading}
            >
              Sign in
            </Button>
          </Form.Item>

          <p className="font-semibold text-sm text-center">
            Dont have an account?{" "}
            <Link to={webRoutes.register} className="cursor-pointer">
              Sign Up.
            </Link>
          </p>
        </Form>
      </div>
    </section>
  );
}
