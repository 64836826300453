// store.js
import { create } from "zustand";
import { apiList } from "../routes/api";
import axiosInstance from "../routes/axiosInstance";
import { AuthRefreshToken, GetUserRole, getUserToken } from "../utils/helper";
import { Constant } from "../utils/constant";

let url;
const userStore = create((set) => ({
  profile: [],
  myProfile: [],

  getUserProfile: async (params) => {
    await AuthRefreshToken();
    switch (GetUserRole()) {
      case Constant.student:
        url = apiList.userProfile;
        break;

      case Constant.agent:
        url = apiList.agentStudentList;
        break;

      case Constant.counsellor:
        url = apiList.counsellorStudentList;
        break;

      default:
        url = apiList.adminAllUsers;
        break;
    }
    const response = await axiosInstance.get(params ? url + params : url, {
      headers: {
        Authorization: "Bearer " + getUserToken(),
      },
    });
    if (response.status === 200) {
      set({ profile: response.data });
    }
  },

  getMyProfile: async () => {
    await AuthRefreshToken();
    const response = await axiosInstance.get(apiList.userProfile, {
      headers: {
        Authorization: "Bearer " + getUserToken(),
      },
    });
    console.log(response);
    if (response.status === 200) {
      set({ myProfile: response.data });
    }
  },

  updateMyProfile: async (data) => {
    await AuthRefreshToken();
    const response = await axiosInstance.patch(apiList.userProfile, data, {
      headers: {
        Authorization: "Bearer " + getUserToken(),
        "Content-type": "multipart/form-data",
      },
    });
    console.log(response);
    if (response.status === 200) {
      return response.data;
    }
  },

  updateUserPassword: async (data) => {
    await AuthRefreshToken();
    const response = await axiosInstance.put(apiList.userPassword, data, {
      headers: {
        Authorization: "Bearer " + getUserToken(),
      },
    });
    console.log(response);
    if (response.status === 200) {
      return response.data;
    }
  },
}));
// agentStudentApplicationCreate
export default userStore;
