import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { Link, useSearchParams } from "react-router-dom";
import publicStore from "../../../stores/publicStore";
import studentStore from "../../../stores/studentStore";

export default function LearnIndex() {
  const [searchParams] = useSearchParams();
  const courseId = searchParams.get("course");

  const {
    courseInfo,
    videoCoursesList,
    fetchVideoCourseInfo,
    videoCourseLession,
    fetchVideoCourseLession,
    fetchVideoCourseList,
  } = publicStore();

  const {
    createVideoCourseEnrollments,
    videoCourseEnrollmentsList,
    getVideoCourseEnrollmentsList,
  } = studentStore();

  const [videoUrl, setVideoUrl] = useState(null);

  useEffect(() => {
    sessionStorage.removeItem("view-course-video");
    if (courseId) {
      getRequiredData();
    }
    getVideoCourseEnrollmentsList();
    fetchVideoCourseList("398ab90a-a2e9-4807-9ef7-ec980f115901");
  }, []);

  // useEffect(() => {
  //   checkIfVideoExist();
  // }, [videoCourseEnrollmentsList]);

  const getRequiredData = async () => {
    await getVideoCourseEnrollmentsList();
    await fetchVideoCourseInfo(courseId);

    const params = new URLSearchParams();
    params.append("course", courseId);
    params.append("limit", 200);

    await fetchVideoCourseLession(params.toString());
  };

  const checkIfVideoExist = () => {
    const isCourseExist = videoCourseEnrollmentsList.some(
      (item) => item.course === courseId
    );
    if (!isCourseExist) {
      createVideoCourseEnrollments({
        course: courseId,
      });
    }
  };

  console.log(videoCourseEnrollmentsList);

  return (
    <section className="container">
      {courseInfo.length !== 0 && (
        <>
          <h1 className="mb-5">{courseInfo?.name}</h1>

          <div class="grid grid-cols-3 gap-4">
            <div className="col-span-2">
              <ReactPlayer
                url={videoUrl ? videoUrl : courseInfo?.first_lesson_video_url}
                controls={true}
                width="100%"
                height="500px"
                className="mb-5 rounded-xl overflow-hidden"
              />
            </div>

            <div className="">
              <h3 className="mb-3">Course content</h3>
              <ul>
                {videoCourseLession.results &&
                  videoCourseLession.results.map((lession, index) => {
                    return (
                      <li
                        className={`bg-theme-primary text-sm p-3 mb-2 cursor-pointer hover:bg-black rounded-xl ${
                          lession.url === videoUrl ? "bg-theme-primary text-black" : "text-white"
                        }`}
                        onClick={() => setVideoUrl(lession.url)}
                      >{console.log(lession.url)}{console.log("courseInfo: ", courseInfo?.first_lesson_video_url)}
                        {index + 1}. {lession.name}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </>
      )}

      <div className="mb-5">
        <h2 className="mb-3">My Learning</h2>
        <div className="grid lg:grid-cols-4 gap-5">
          {videoCourseEnrollmentsList &&
            videoCourseEnrollmentsList.map((course) => {
              return (
                <Link to={"/dashboard?course=" + course.course}>
                  <div className="bg-theme-primary text-white rounded-xl p-3 cursor-pointer">
                    <ReactPlayer
                      url={course.course_detail.first_lesson_video_url}
                      controls={true}
                      width="100%"
                      height="200px"
                      className="mb-5"
                    />
                    <h4>{course.course_detail.name}</h4>
                  </div>
                </Link>
              );
            })}
        </div>
      </div>

      {/* <div>
        <h2 className="mb-3">Recommend for you</h2>
        <div className="grid grid-cols-4 gap-5">
          {videoCoursesList.results &&
            videoCoursesList.results.map((course) => {
              return (
                <div className="bg-theme-primary text-white rounded-xl p-3 cursor-pointer">
                  <img
                    src={videoThumbnail}
                    alt=""
                    className="rounded-lg mb-3 w-full"
                  />
                  <h4>{course.name}</h4>
                </div>
              );
            })}
        </div>
      </div> */}
    </section>
  );
}
