import { Pagination } from "antd";
import React, { useState } from "react";

const defaultPageSizeOptions = ["12", "24", "36", "48", "60"];
export default function BasicPagination(props) {
  const { total, limit, defaultCurrent, pageSizeOptions } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(limit);

  const onChange = (page, pageSize) => {
    setCurrentPage(page);
    defaultCurrent(page);
    setPageSize(pageSize);
    pageSizeOptions(pageSize);
  };

  return (
    <div className="my-6 flex justify-end">
      <Pagination
        defaultCurrent={currentPage}
        total={total}
        showTotal={(total) => `Total ${total} items`}
        pageSize={pageSize}
        pageSizeOptions={defaultPageSizeOptions}
        onChange={onChange}
      />
    </div>
  );
}
