import { Button, Form, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";
import publicStore from "../../../stores/publicStore";
import { useState } from "react";
import { toastSuccess } from "../../../utils/helper";

export default function GeneralInquiries() {
  const { general_inquiries_create } = publicStore();

  const [loading, setloading] = useState(false);

  const [form] = Form.useForm();

  const onFinish = (value) => {
    console.log(value);
    setloading(true);
    general_inquiries_create(value)
      .then(() => {debugger;
        form.resetFields();
        toastSuccess("Your message has been sent successfully!");
      })
      .catch()
      .finally(() => setloading(false));
  };
  return (
    <section>
      <div className="container">
        <h1 className="text-center mb-3">General Inquiries</h1>
        <p className="text-center mb-8">
          Do you have any question? Please let us know.
        </p>

        <div className="p-5 bg-white rounded-xl drop-shadow">
          <Form
            form={form}
            layout={"vertical"}
            requiredMark={false}
            onFinish={onFinish}
            autoComplete="off"
            scrollToFirstError
            // onValuesChange={onFormLayoutChange}
          >
            <div class="grid grid-cols-2 gap-5 mb-5">
              <Form.Item
                label="Full Name"
                className="required font-semibold mb-0"
                name={"fullname"}
                rules={[
                  {
                    required: true,
                    message: "Please input your Full Name!",
                  },
                ]}
              >
                <Input className="mb-2" size="large" />
              </Form.Item>

              <Form.Item
                label="Address"
                className="required font-semibold mb-0"
                name={"address"}
                rules={[
                  {
                    required: true,
                    message: "Please input your address!",
                  },
                ]}
              >
                <Input className="mb-2" size="large" />
              </Form.Item>

              <Form.Item
                label="Phone No."
                className="required font-semibold mb-0"
                name={"phone"}
                rules={[
                  {
                    required: true,
                    message: "Please input your phone!",
                  },
                ]}
              >
                <Input className="mb-2" size="large" />
              </Form.Item>

              <Form.Item
                label="Email"
                className="required font-semibold mb-0"
                name={"email"}
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input className="mb-2" size="large" />
              </Form.Item>

              <div class="col-span-2">
                <Form.Item
                  label="Message"
                  className="required font-semibold mb-0"
                  name={"message"}
                  rules={[
                    {
                      required: true,
                      message: "Please input your message!",
                    },
                  ]}
                >
                  <TextArea rows={4} />
                </Form.Item>
              </div>
            </div>
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Form>
        </div>
      </div>
    </section>
  );
}
