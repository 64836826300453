import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Dashboard from ".";
import { webRoutes } from "../../routes/web";
import { Constant } from "../../utils/constant";
import { GetUserRole } from "../../utils/helper";
import LearnIndex from "./learn";

export default function DashboardPage() {
  const navigate = useNavigate();

  useEffect(() => {
    // if (GetUserRole() !== Constant.student) navigate(webRoutes.studentLists);
  }, []);

  const getContent = () => {
    switch (GetUserRole()) {
      case Constant.student:
        return <LearnIndex />;

      default:
        return <Dashboard />;
    }
  };

  return <div>{getContent()}</div>;
}
