import React from 'react';
import { Layout, Breadcrumb, Row, Col, Card, Statistic, List, Avatar, Typography, Progress } from 'antd';
import {
  TeamOutlined,
  UserOutlined,
  SolutionOutlined,
  BookOutlined,
  GlobalOutlined,
  HomeOutlined,
  ContainerOutlined,
  ClockCircleOutlined,
  QuestionCircleOutlined,
  LineChartOutlined,
  RiseOutlined,
} from '@ant-design/icons';

const { Header, Content, Footer } = Layout;
const { Title } = Typography;

const Dashboard = () => {
  // Mock data for demonstration
  const recentApplications = [
    { id: 1, name: 'John Doe', course: 'MBA', country: 'USA' },
    { id: 2, name: 'Jane Smith', course: 'Computer Science', country: 'Canada' },
    { id: 3, name: 'Bob Johnson', course: 'Medicine', country: 'Australia' },
  ];

  const topCounsellors = [
    { id: 1, name: 'Alice Brown', applications: 50 },
    { id: 2, name: 'David Lee', applications: 45 },
  ];

  const topAgents = [
    { id: 1, name: 'Agent 1', applications: 60 },
    { id: 2, name: 'Agent 2', applications: 55 },
  ];

  const popularCourses = [
    { id: 1, course: 'MBA', percentage: 80 },
    { id: 2, course: 'Computer Science', percentage: 70 },
  ];

  const popularCountries = [
    { id: 1, country: 'USA', percentage: 85 },
    { id: 2, country: 'Canada', percentage: 75 },
  ];

  return (
    <div className="site-layout-background">
      <Row gutter={16}>
        <Col span={8}>
          <Card>
            <Statistic
              title="Total Students"
              value={1128}
              prefix={<TeamOutlined />}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="Applications Submitted"
              value={234}
              prefix={<SolutionOutlined />}
              valueStyle={{ color: '#1890ff' }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="Counselors Available"
              value={87}
              prefix={<UserOutlined />}
              valueStyle={{ color: '#cf1322' }}
            />
          </Card>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: 16 }}>
        <Col span={8}>
          <Card>
            <Statistic
              title="Total Institutes"
              value={56}
              prefix={<HomeOutlined />}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="Countries"
              value={12}
              prefix={<GlobalOutlined />}
              valueStyle={{ color: '#cf1322' }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="Pending Inquiries"
              value={45}
              prefix={<QuestionCircleOutlined />}
              valueStyle={{ color: '#1890ff' }}
            />
          </Card>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: 32 }}>
        <Col span={12}>
          <Card title="Recent Applications" bordered={false}>
            <List
              itemLayout="horizontal"
              dataSource={recentApplications}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar icon={<SolutionOutlined />} />}
                    title={<a href="#">{item.name}</a>}
                    description={`${item.course} - ${item.country}`}
                  />
                </List.Item>
              )}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Top Counsellors" bordered={false}>
            <List
              itemLayout="horizontal"
              dataSource={topCounsellors}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar icon={<UserOutlined />} />}
                    title={<a href="#">{item.name}</a>}
                    description={`Applications: ${item.applications}`}
                  />
                </List.Item>
              )}
            />
          </Card>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: 32 }}>
        <Col span={12}>
          <Card title="Top Agents" bordered={false}>
            <List
              itemLayout="horizontal"
              dataSource={topAgents}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar icon={<ContainerOutlined />} />}
                    title={<a href="#">{item.name}</a>}
                    description={`Applications: ${item.applications}`}
                  />
                </List.Item>
              )}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Popular Courses" bordered={false}>
            {popularCourses.map((course) => (
              <div key={course.id} style={{ marginBottom: 24 }}>
                <Title level={5}>{course.course}</Title>
                <Progress percent={course.percentage} status="active" />
              </div>
            ))}
          </Card>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: 32 }}>
        <Col span={12}>
          <Card title="Popular Countries" bordered={false}>
            {popularCountries.map((country) => (
              <div key={country.id} style={{ marginBottom: 24 }}>
                <Title level={5}>{country.country}</Title>
                <Progress percent={country.percentage} status="active" />
              </div>
            ))}
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Weekly Stats" bordered={false}>
            <Statistic
              title="New Students This Week"
              value={78}
              prefix={<LineChartOutlined />}
              valueStyle={{ color: '#3f8600' }}
            />
            <Statistic
              title="New Applications This Week"
              value={45}
              prefix={<RiseOutlined />}
              valueStyle={{ color: '#1890ff' }}
              style={{ marginTop: 16 }}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
