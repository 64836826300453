// store.js
import { create } from "zustand";
import axiosInstance from "../routes/axiosInstance";
import { apiList } from "../routes/api";
import { AuthRefreshToken, GetUserRole, getUserToken } from "../utils/helper";
import { Constant } from "../utils/constant";

const authStore = create((set) => ({
  login: async (params) => {
    try {
      const response = await axiosInstance.post(apiList.login, params);
      if (response.status === 200) {
        return response.data;
      } else {
        return new Error("API Error");
      }
    } catch (error) {
      return new Error(error);
    }
  },

  signup: async (params) => {
    const response = await axiosInstance.post(
      apiList.apiSignup,
      JSON.stringify(params)
    );
    if (response.status === 200) {
      return response.data;
    }
  },

  forgetPassword: async (params) => {
    try {
      const response = await axiosInstance.post(
        apiList.apiForgetPassword,
        JSON.stringify(params)
      );
      return response;
    } catch (error) {
      throw error.response.data;
    }
  },

  passwordResetConfirmCreate: async (params) => {
    try {
      const response = await axiosInstance.post(
        apiList.apiPasswordResetConfirm,
        JSON.stringify(params)
      );
      return response;
    } catch (error) {
      throw error.response.data;
    }
  },

  registerStudentFromAuth: async (params) => {
    let url;
    await AuthRefreshToken();
    if (GetUserRole()) {
      switch (GetUserRole()) {
        case Constant.agent:
          url = apiList.agentCreateStudent;
          break;

        case Constant.counsellor:
          url = apiList.counsellorCreateStudent;
          break;

        default:
          url = apiList.adminCreateUser;
          break;
      }
    }
    const response = await axiosInstance.post(url, JSON.stringify(params), {
      headers: {
        Authorization: "Bearer " + getUserToken(),
      },
    });
    if (response.status === 200) {
      return response.data;
    }
  },

  updateStudentFromAuth: async (id, data) => {
    await AuthRefreshToken();
    let url;
    if (GetUserRole()) {
      switch (GetUserRole()) {
        case Constant.admin:
          url = apiList.adminAllUsers;
          break;

        case Constant.super_admin:
          url = apiList.adminAllUsers;
          break;

        case Constant.agent:
          url = apiList.adminAllUsers;
          break;

        case Constant.counsellor:
          url = apiList.adminAllUsers;
          break;

        default:
          url = apiList.adminAllUsers;
          break;
      }
    }
    const response = await axiosInstance.patch(url + id, JSON.stringify(data), {
      headers: {
        Authorization: "Bearer " + getUserToken(),
      },
    });
    if (response.status === 200) {
      return response.data;
    }
  },

  updateCounsellorFromAuth: async (id, data) => {
    await AuthRefreshToken();
    let url;
    if (GetUserRole()) {
      switch (GetUserRole()) {
        case Constant.agent:
          url = apiList.adminAllUsers;
          break;

        case Constant.counsellor:
          url = apiList.adminAllUsers;
          break;

        default:
          url = apiList.adminAllUsers;
          break;
      }
    }
    const response = await axiosInstance.patch(url + id, JSON.stringify(data), {
      headers: {
        Authorization: "Bearer " + getUserToken(),
      },
    });
    if (response.status === 200) {
      return response.data;
    }
  },

  deleteUser: async (params) => {
    await AuthRefreshToken();

    let url;
    if (GetUserRole()) {
      switch (GetUserRole()) {
        case Constant.agent:
          url = apiList.agentCreateStudent;
          break;

        case Constant.counsellor:
          url = apiList.counsellorCreateStudent;
          break;

        default:
          url = apiList.adminAllUsers;
          break;
      }
    }
    const response = await axiosInstance.delete(url + params, {
      headers: {
        Authorization: "Bearer " + getUserToken(),
      },
    });
    if (response.status === 204) {
      return response.data;
    }
  },
}));

export default authStore;
