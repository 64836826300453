import { Drawer, List } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { webRoutes } from "../../routes/web";
import { getAuth } from "../../auth/AuthHelpers";

export default function AsideNavbarDrawer(props) {
  const { open, onClose } = props;

  const data = [
    {
      title: "Institute List",
      url: webRoutes.allInstituteList,
    },
    {
      title: "Log in",
      url: webRoutes.login,
    },
    {
      title: "Sign up",
      url: webRoutes.register,
    },
    {
      title: "Privacy Policy",
      url: "privacy-policy",
    },
    {
      title: "Terms & Conditions",
      url: "terms-and-conditions",
    },
  ];
  console.log(getAuth());

  return (
    <Drawer
      title="Menu"
      onClose={onClose}
      open={open}
      style={{ backgroundColor: "#D9D9D9" }}
    >
      <List
        bordered={false}
        dataSource={data}
        renderItem={(item) =>
          getAuth() && item.title !== "Log in" && item.title !== "Sign up" ? (
            <List.Item className="font-bold text-lg">
              <Link to={item.url} onClick={onClose} className="text-black">
                {item.title}
              </Link>
            </List.Item>
          ) : (
            <List.Item className="font-bold text-lg">
              <Link to={item.url} onClick={onClose} className="text-black">
                {item.title}
              </Link>
            </List.Item>
          )
        }
      />
    </Drawer>
  );
}
