import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Input, Popconfirm, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import BasicPagination from "../../../components/BasicPagination";
import adminStore from "../../../stores/adminStore";
import { Constant } from "../../../utils/constant";
import { toastSuccess } from "../../../utils/helper";
import DocumentsForm from "./DocumentsForm";
import Debounce from "../../../utils/Debounce";

const type = Constant.documents;
export default function DocumentsList() {
  const { academicDocumentList, fetchDocuments, deleteModalStore } =
    adminStore();

  const [formData, setformData] = useState(null);

  const [isModalOpen, setisModalOpen] = useState(false);
  const [mode, setMode] = useState("create");
  const [loading, setloading] = useState(true);
  const [limit, setlimit] = useState(24);
  const [offset, setoffset] = useState(0);
  const setDefaultCurrent = (val) => setoffset(val * limit - limit);
  const setpageSizeOptions = (val) => setlimit(val);
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = Debounce(search, 500);

  useEffect(() => {
    initialApiCall();
  }, [limit, offset, debouncedSearchTerm]);

  const initialApiCall = () => {
    setloading(true);
    const params = new URLSearchParams();
    params.append("limit", limit);
    params.append("offset", offset);
    params.append("search", search);
    params.append("order", "-updated_at");

    fetchDocuments(params.toString()).finally(() => {
      setloading(false);
    });
  };

  const confirm = (id) => {
    deleteModalStore(id, type).then(() => {
      toastSuccess("Deleted successfully!");
      initialApiCall();
    });
  };

  const edit = (formData) => {
    setMode("edit");
    setformData(formData);
    setisModalOpen(true);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Order",
      dataIndex: "order",
      key: "order",
    },
    {
      title: "Group",
      dataIndex: "group",
      key: "group",
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, record) => (
        <span>{moment(record.created_at).format("YYYY-MM-DD")}</span>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      align: "right",
      fixed: "right",
      render: (text, record) => (
        <div className="flex justify-end gap-2">
          <Tooltip title="Edit">
            <div
              onClick={() => edit(record)}
              className="shadow py-1 px-2 text-slate-500 bg-amber-100 rounded-md cursor-pointer hover:bg-amber-200 hover:text-slate-600 hover:shadow"
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </div>
          </Tooltip>

          <Tooltip title="Delete">
            <Popconfirm
              placement="topRight"
              title="Delete item"
              description="Are you sure to delete this item?"
              onConfirm={() => confirm(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <div className="shadow py-1 px-2 text-red-500 bg-red-100 rounded-md cursor-pointer hover:bg-red-200 hover:text-red-600 hover:shadow">
                <FontAwesomeIcon icon={faTrashCan} />
              </div>
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="flex justify-between items-center mb-5">
        <h3>Document Types</h3>

        <div className="flex items-center gap-5">
          <div>
            <Input
              placeholder="Search document"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </div>
          <Button
            type="primary"
            onClick={() => {
              setMode("create");
              setisModalOpen(true);
            }}
          >
            Add document
          </Button>
        </div>

        <DocumentsForm
          mode={mode}
          type={type}
          isModalOpen={isModalOpen}
          handleCancel={() => {
            setisModalOpen(false);
            setformData({});
          }}
          fetchDataAfterFormSubmit={initialApiCall}
          formData={formData}
        />
      </div>

      <Table
        bordered
        size="small"
        columns={columns}
        dataSource={academicDocumentList.results}
        loading={loading}
        pagination={false}
        scroll={{ x: "max-content" }}
        className="drop-shadow-md"
      />

      <BasicPagination
        total={academicDocumentList.count}
        limit={limit}
        defaultCurrent={setDefaultCurrent}
        pageSizeOptions={setpageSizeOptions}
      />
    </div>
  );
}
