import { Button, Form, Input, Modal, Switch } from "antd";
import React, { useEffect } from "react";
import adminStore from "../stores/adminStore";
import { toastError, toastSuccess } from "../utils/helper";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Constant } from "../utils/constant";
import { webRoutes } from "../routes/web";

export default function PostModal(props) {
  const [form] = Form.useForm();
  const {
    mode,
    isModalOpen,
    handleCancel,
    type,
    fetchDataAfterFormSubmit,
    formData,
    children,
    width,
  } = props;
  const { postModalStore, updateModalStore } = adminStore();

  const onFinish = (values) => {
    if (values.is_active === undefined) values.is_active = false;

    // icon for categories
    if (type === Constant.coursesCategories) {
      if (values.icon && mode === "edit") {
        if (values.icon?.fileList === undefined) {
          delete values.icon;
        } else {
          values.icon = values.icon.fileList[0].originFileObj;
        }
      }

      if (values.icon?.fileList && mode === "create") {
        values.icon = values.icon.fileList[0].originFileObj;
      }
    }

    // Logo for institute
    if (type === Constant.institutes) {
      if (values.logo && mode === "edit") {
        if (values.logo?.fileList === undefined) {
          delete values.logo;
        } else {
          values.logo = values.logo.fileList[0].originFileObj;
        }
      }

      if (values.logo?.fileList && mode === "create") {
        values.logo = values.logo.fileList[0].originFileObj;
      }
    }

    if (mode === "create") {
      postModalStore(values, type)
        .then((response) => {
          if (response.status === 200) {
            toastSuccess("Created successfully!");
            handleCancel();
            fetchDataAfterFormSubmit();
          }
        })
        .catch((error) => {
          toastError(error.message);
        });
    } else {
      const data = {
        id: formData.id,
        data: values,
      };
      updateModalStore(data, type).then((response) => {
        if (response.status === 200) {
          toastSuccess("Updated successfully!");
          handleCancel();
          fetchDataAfterFormSubmit();
        }
      });
    }
  };

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(formData);
  }, [formData, form]);

  return (
    <Modal
      maskClosable={false}
      title={type}
      open={isModalOpen}
      footer={false}
      onCancel={handleCancel}
      width={width}
    >
      <Form
        form={form}
        layout={"vertical"}
        style={{
          marginTop: "20px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        requiredMark={false}
        onFinish={onFinish}
        autoComplete="off"
        scrollToFirstError
      >
        {children}
        {(window.location.pathname === "/courses-list" ||
          window.location.pathname === "/institutes-list") && (
          <Form.Item
            label="Description"
            name="description"
            className="font-semibold col-span-3"
            // rules={[
            //   {
            //     required: true,
            //   },
            // ]}
          >
            <CKEditor
              editor={ClassicEditor}
              data={formData?.description ? formData?.description : ""}
              onChange={(event, editor) => {
                const data = editor.getData();
                form.setFieldsValue({ description: data });
              }}
            />
          </Form.Item>
        )}

        {window.location.pathname !== webRoutes.documentslist && window.location.pathname !== webRoutes.applicationStatus && (
          <Form.Item name="is_active" label="Status" className="font-semibold">
            <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
          </Form.Item>
        )}

        <div className="text-end mb-0">
          <Button type="primary" htmlType="submit" className="w-full">
            Submit
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
