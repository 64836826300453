import React from "react";
import { Outlet } from "react-router-dom";
import WebsiteNavbar from "../navbar/websiteNavbar";
import Footer from "../footer/footer";

export default function WebsiteLayout() {
  return (
    <div>
      <WebsiteNavbar />
      <main style={{ backgroundColor: "#f6f6f6" }}>
        <Outlet />
      </main>
      <Footer/>
    </div>
  );
}
