import { Modal } from "antd";
import React, { useState } from "react";
import publicStore from "../../../stores/publicStore";

export default function CompareCourse(props) {
  const { isModalOpen, handleCancel, compareCourse } = props;

  const { courses } = publicStore();

  const showCompareButton = (id) => {
    return compareCourse.find((item) => item.id === id) ? true : false;
  };

  return (
    <Modal
      title="Compare Courses"
      open={isModalOpen}
      onCancel={handleCancel}
      width={1500}
    >
      <table className="w-full cm-table">

          <tr>
            <th className="text-start">Courses title</th>
            {courses.results &&
              courses.results.map(
                (category) =>
                  showCompareButton(category.id) && <td>{category.title}</td>
              )}
          </tr>

          <tr>
            <th className="text-start">Degree Name</th>
            {courses.results &&
              courses.results.map(
                (category) =>
                  showCompareButton(category.id) && (
                    <td>{category.degree.name}</td>
                  )
              )}
          </tr>

          <tr>
            <th className="text-start">Course Duration</th>
            {courses.results &&
              courses.results.map(
                (category) =>
                  showCompareButton(category.id) && (
                    <td>{category.duration} Year's</td>
                  )
              )}
          </tr>

          <tr>
            <th className="text-start">Fees</th>
            {courses.results &&
              courses.results.map(
                (category) =>
                  showCompareButton(category.id) && (
                    <td>{category.fee_international}</td>
                  )
              )}
          </tr>

          <tr>
            <th className="text-start">Fee Year</th>
            {courses.results &&
              courses.results.map(
                (category) =>
                  showCompareButton(category.id) && (
                    <td>{category.fee_year}</td>
                  )
              )}
          </tr>

          <tr>
            <th className="text-start">Institute Type</th>
            {courses.results &&
              courses.results.map(
                (category) =>
                  showCompareButton(category.id) && (
                    <td>{category.institute.institute_type}</td>
                  )
              )}
          </tr>

          <tr>
            <th className="text-start">Institute Name</th>
            {courses.results &&
              courses.results.map(
                (category) =>
                  showCompareButton(category.id) && (
                    <td>{category.institute.title}</td>
                  )
              )}
          </tr>

          <tr>
            <th className="text-start">Institute Address</th>
            {courses.results &&
              courses.results.map(
                (category) =>
                  showCompareButton(category.id) && (
                    <td>{category.institute.address}</td>
                  )
              )}
          </tr>

          <tr>
            <th className="text-start">Institute Rank</th>

            {courses.results &&
              courses.results.map(
                (category) =>
                  showCompareButton(category.id) && (
                    <td>{category.institute.rank}</td>
                  )
              )}
          </tr>

          <tr>
            <th className="text-start">Institute Rank Year</th>

            {courses.results &&
              courses.results.map(
                (category) =>
                  showCompareButton(category.id) && (
                    <td>{category.institute.rank_year}</td>
                  )
              )}
          </tr>

          <tr>
            <th className="text-start">Country</th>
            {courses.results &&
              courses.results.map(
                (category) =>
                  showCompareButton(category.id) && (
                    <td>{category.institute.country_detail.name}</td>
                  )
              )}
          </tr>

          <tr>
            <th className="text-start">Currency</th>
            {courses.results &&
              courses.results.map(
                (category) =>
                  showCompareButton(category.id) && (
                    <td>{category.institute.country_detail.currency.name}</td>
                  )
              )}
          </tr>

          <tr>
            <th className="text-start">Currency Symbol</th>

            {courses.results &&
              courses.results.map(
                (category) =>
                  showCompareButton(category.id) && (
                    <td>{category.institute.country_detail.currency.symbol}</td>
                  )
              )}
          </tr>

      </table>
    </Modal>
  );
}
