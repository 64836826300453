import { InboxOutlined } from "@ant-design/icons";
import { Form, Input, Select, Upload } from "antd";
import React, { useEffect } from "react";
import PostModal from "../../../components/PostModal";
import adminStore from "../../../stores/adminStore";
const { Dragger } = Upload;

export default function InstituteForm(props) {
  const { countries, fetchCountries } = adminStore();

  const { formData } = props;

  const params = new URLSearchParams();
  params.append("is_active", true);
  params.append("order", "name");
  params.append("limit", "200");
  useEffect(() => {
    fetchCountries(params.toString());
  }, []);

  console.log(formData);
  const draggerProps = {
    name: "file",
    multiple: false,
    accept: "image/png, image/jpeg",
    maxCount: 1,
    beforeUpload: (file) => {
      return false;
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const getCountries = () => {
    return countries?.results?.map((country) => ({
      label: country.name,
      value: country.id,
    }));
  };

  return (
    <>
      <PostModal {...props}>
        <div className="grid lg:grid-cols-2 gap-4">
          <Form.Item
            label="Country"
            name="country"
            className="required font-semibold mb-0"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              size="large"
              showSearch
              allowClear
              placeholder="Select country"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={getCountries()}
              className="w-full"
            />
          </Form.Item>

          <Form.Item
            label="Name"
            name="title"
            className="required font-semibold mb-0"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Name" size="large" />
          </Form.Item>

          <Form.Item
            label="Address"
            name="address"
            className="required font-semibold mb-0"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Address" size="large" />
          </Form.Item>

          <Form.Item
            label="Established on"
            name="established_on"
            className="font-semibold mb-0"
            rules={[
              {
                pattern: /^(18\d{2}|19\d{2}|20\d{2})$/, // Regular expression for a valid year between 1900 and 2099
                message: "Please input a valid year between 1800 and 2099",
              },
            ]}
          >
            <Input placeholder="Established on" size="large" />
          </Form.Item>

          <Form.Item
            label="Institute Type"
            name="institute_type"
            className="required font-semibold mb-0"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Institute Type" size="large" />
          </Form.Item>

          <Form.Item
            label="Rank Year"
            name="rank_year"
            className="font-semibold mb-0"
            rules={[
              {
                pattern: /^(18\d{2}|19\d{2}|20\d{2})$/, // Regular expression for a valid year between 1900 and 2099
                message: "Please input a valid year between 1800 and 2099",
              },
            ]}
          >
            <Input placeholder="Rank year" size="large" />
          </Form.Item>

          <Form.Item label="Rank" name="rank" className="font-semibold mb-0">
            <Input placeholder="Rank" size="large" />
          </Form.Item>

          <Form.Item
            label="URL"
            name="url"
            className="font-semibold mb-0"
            rules={[
              {
                type: "url",
                message: "Please input a valid URL!",
              },
            ]}
          >
            <Input placeholder="url" size="large" />
          </Form.Item>

          <Form.Item
            label="Video URL"
            name="video_url"
            className="font-semibold mb-0"
            rules={[
              {
                type: "url",
                message: "Please input a valid URL!",
              },
            ]}
          >
            <Input placeholder="Video URL" size="large" />
          </Form.Item>
        </div>

        <div className="grid lg:grid-cols-2 gap-4 mt-5">
          <Form.Item label="Logo" name="logo" className="font-semibold">
            <Dragger {...draggerProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </Dragger>
          </Form.Item>

          {formData?.logo && (
            <div>
              <p className="font-semibold">Previous Logo</p>
              <img
                src={formData?.logo}
                alt="logo"
                width={180}
                className="mx-auto mt-4"
              />
            </div>
          )}
        </div>
      </PostModal>
    </>
  );
}
