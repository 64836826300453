import {
  faBars,
  faBook,
  faBuildingColumns,
  faClipboardQuestion,
  faDashboard,
  faFile,
  faGlobe,
  faGraduationCap,
  faHourglassHalf,
  faRightFromBracket,
  faUser,
  faUsers,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar } from "antd";
import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import avatarImg from "../../assets/images/collections/7294794.jpg";
import { useAuth } from "../../auth/Auth";
import { webRoutes } from "../../routes/web";
import { Constant } from "../../utils/constant";
import Permission from "../auth/Permission";
import { getAuth } from "../../auth/AuthHelpers";

let endpoint = window.location.href;
export default function DashboardLayout() {
  const { logout } = useAuth();
  const [pathname, setpathname] = useState(window.location.pathname);
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    console.log(endpoint);
    setpathname(window.location.pathname);
  }, []);

  return (
    <div className="dashboard">
      <aside className="aside-menu">
        <div className="aside-menu-logo">
          <h3 className="text-center mb-3">
            <Link to={"/"}>EDUDOKO</Link>
          </h3>
        </div>
        <hr></hr>
        <ul className="cm-aside-menu mt-12">
          <Permission
            allowedRoles={[
              Constant.agent,
              Constant.counsellor,
              Constant.admin,
              Constant.super_admin,
            ]}
          >
            <Link
              to={webRoutes.dashboard}
              onClick={() => {
                toggleVisibility();
                setpathname(webRoutes.dashboard);
              }}
            >
              <li
                className={pathname === webRoutes.dashboard ? "active" : ""}
              >
                <FontAwesomeIcon icon={faDashboard} className="mr-3" /> Dashboard
              </li>
            </Link>
            <Link
              to={webRoutes.studentLists}
              onClick={() => {
                toggleVisibility();
                setpathname(webRoutes.studentLists);
              }}
            >
              <li
                className={pathname === webRoutes.studentLists ? "active" : ""}
              >
                <FontAwesomeIcon icon={faUsers} className="mr-3" /> Students
              </li>
            </Link>
          </Permission>

          <Permission allowedRoles={[Constant.admin, Constant.super_admin]}>
            <Link
              to={webRoutes.counsellorLists}
              onClick={() => {
                toggleVisibility();
                setpathname(webRoutes.counsellorLists);
              }}
            >
              <li
                className={
                  pathname === webRoutes.counsellorLists ? "active" : ""
                }
              >
                <FontAwesomeIcon icon={faUsers} className="mr-3" /> Counsellors
              </li>
            </Link>
            <Link
              to={webRoutes.agentLists}
              onClick={() => {
                toggleVisibility();
                setpathname(webRoutes.agentLists);
              }}
            >
              <li className={pathname === webRoutes.agentLists ? "active" : ""}>
                <FontAwesomeIcon icon={faUsers} className="mr-3" /> Agents
              </li>
            </Link>
            <Link
              to={webRoutes.degreesLists}
              onClick={() => {
                toggleVisibility();
                setpathname(webRoutes.degreesLists);
              }}
            >
              <li
                className={pathname === webRoutes.degreesLists ? "active" : ""}
              >
                <FontAwesomeIcon icon={faGraduationCap} className="mr-3" />{" "}
                Degrees
              </li>
            </Link>
            <Link
              to={webRoutes.countriesList}
              onClick={() => {
                toggleVisibility();
                setpathname(webRoutes.countriesList);
              }}
            >
              <li
                className={pathname === webRoutes.countriesList ? "active" : ""}
              >
                <FontAwesomeIcon icon={faGlobe} className="mr-3" /> Countries
              </li>
            </Link>
            <Link
              to={webRoutes.coursesList}
              onClick={() => {
                toggleVisibility();
                setpathname(webRoutes.coursesList);
              }}
            >
              <li
                className={pathname === webRoutes.coursesList ? "active" : ""}
              >
                <FontAwesomeIcon icon={faBook} className="mr-3" /> Courses
              </li>
            </Link>
            <Link
              to={webRoutes.courseCategoriesList}
              onClick={() => {
                toggleVisibility();
                setpathname(webRoutes.courseCategoriesList);
              }}
            >
              <li
                className={
                  pathname === webRoutes.courseCategoriesList ? "active" : ""
                }
              >
                <FontAwesomeIcon icon={faBook} className="mr-3" /> Course
                Categories
              </li>
            </Link>
            <Link
              to={webRoutes.institutesList}
              onClick={() => {
                toggleVisibility();
                setpathname(webRoutes.institutesList);
              }}
            >
              <li
                className={
                  pathname === webRoutes.institutesList ? "active" : ""
                }
              >
                <FontAwesomeIcon icon={faBuildingColumns} className="mr-3" />{" "}
                Institutes
              </li>
            </Link>
            <Link
              to={webRoutes.documentslist}
              onClick={() => {
                toggleVisibility();
                setpathname(webRoutes.documentslist);
              }}
            >
              <li
                className={pathname === webRoutes.documentslist ? "active" : ""}
              >
                <FontAwesomeIcon icon={faFile} className="mr-3" /> Document Types
              </li>
            </Link>
            <Link
              to={webRoutes.applicationStatus}
              onClick={() => {
                toggleVisibility();
                setpathname(webRoutes.applicationStatus);
              }}
            >
              <li
                className={pathname === webRoutes.applicationStatus ? "active" : ""}
              >
                <FontAwesomeIcon icon={faHourglassHalf} className="mr-3" /> Application Status
              </li>
            </Link>
          </Permission>

          <Permission allowedRoles={[Constant.admin, Constant.super_admin]}>
            <Link
              to={webRoutes.generalInquires}
              onClick={() => {
                toggleVisibility();
                setpathname(webRoutes.generalInquires);
              }}
            >
              <li
                className={
                  pathname === webRoutes.generalInquires ? "active" : ""
                }
              >
                <FontAwesomeIcon icon={faClipboardQuestion} className="mr-3" />{" "}
                General Inquiries
              </li>
            </Link>
          </Permission>

          <Permission
            allowedRoles={[
              Constant.agent,
              Constant.counsellor,
              Constant.admin,
              Constant.super_admin,
            ]}
          >
            <Link
              to={webRoutes.myProfile}
              onClick={() => {
                toggleVisibility();
                setpathname(webRoutes.myProfile);
              }}
            >
              <li className={pathname === webRoutes.myProfile ? "active" : ""}>
                <FontAwesomeIcon icon={faUser} className="mr-3" /> Profile
              </li>
            </Link>
          </Permission>

          <li onClick={() => logout()}>
            <FontAwesomeIcon icon={faRightFromBracket} className="mr-3" />
            Log out
          </li>
        </ul>
      </aside>
      <main className="p-5">
        <div className="flex items-center justify-end mb-5 lg:hidden">
          {isVisible ? (
            <FontAwesomeIcon
              icon={faXmark}
              className="fa-xl cursor-pointer"
              onClick={() => toggleVisibility()}
            />
          ) : (
            <FontAwesomeIcon
              icon={faBars}
              className="fa-xl cursor-pointer"
              onClick={() => toggleVisibility()}
            />
          )}
        </div>
        <Outlet />
      </main>
    </div>
  );
}
