import loadable from "@loadable/component";
import { createBrowserRouter } from "react-router-dom";
import { AuthInit } from "../auth/Auth";
import DashboardLayout from "../components/layout/DashboardLayout";
import WebsiteLayout from "../components/layout/websiteLayout";
import LoginPage from "../pages/auth/LoginPage";
import RegisterPage from "../pages/auth/RegisterPage";
import DashboardPage from "../pages/dashboard/DashboardPage";
import StudentList from "../pages/dashboard/student/StudentList";
import Home from "../pages/website/home";
import AuthCourseLearnIndex from "../pages/dashboard/learn";
import UserProfile from "../pages/website/profile/userProfile";
import SearchCourses from "../pages/website/searchCourses/searchCourses";
import ScrollToTop from "../utils/ScrollToTop";
import { webRoutes } from "./web";
import CounsellorLists from "../pages/dashboard/counsellor/counsellorLists";
import CounsellorStudentList from "../pages/dashboard/counsellor/counsellorStudentList";
import DegreesList from "../pages/dashboard/degree/DegreesList";
import InstitutesList from "../pages/dashboard/institute/InstitutesList";
import DocumentsList from "../pages/dashboard/documents/DocumentsList";
// import ApplicationStatus from '../pages/dashboard/applicationStatus/ApplicationStatus';

const ResetPassword = loadable(() => import("../pages/auth/resetPassword"));
const ForgetPassword = loadable(() => import("../pages/auth/forgetPassword"));
const ApplicationStatus = loadable(() =>
  import("../pages/dashboard/applicationStatus/ApplicationStatus")
);
const GeneralInquiries = loadable(() =>
  import("../pages/dashboard/generalInquiries/generalInquiries")
);
const MyProfile = loadable(() => import("../pages/dashboard/myProfile"));
const AllInstituteList = loadable(() =>
  import("../pages/website/home/allInstituteList")
);
const AllCourseCategories = loadable(() =>
  import("../pages/website/home/allCourseCategories")
);
const CourseCategoriesList = loadable(() =>
  import("../pages/dashboard/courseCategories/CourseCategoriesList")
);
const CoursesList = loadable(() =>
  import("../pages/dashboard/course/CoursesList")
);
const CountriesList = loadable(() =>
  import("../pages/dashboard/country/CountriesList")
);
const StudentProfile = loadable(() =>
  import("../pages/dashboard/student/StudentProfile")
);
const AgentList = loadable(() => import("../pages/dashboard/agent/AgentList"));
const AgentStudentList = loadable(() =>
  import("../pages/dashboard/agent/AgentStudentList")
);

export const browserRouter = createBrowserRouter([
  {
    element: (
      <>
        <WebsiteLayout />
        <ScrollToTop />
      </>
    ),
    children: [
      {
        path: webRoutes.login,
        element: <LoginPage />,
      },
      {
        path: webRoutes.forgetPassword,
        element: <ForgetPassword />,
      },
      {
        path: webRoutes.passwordReset,
        element: <ResetPassword />,
      },
      {
        path: webRoutes.register,
        element: <RegisterPage />,
      },
      {
        path: webRoutes.home,
        element: <Home />,
      },
      {
        path: webRoutes.searchCourses,
        element: <SearchCourses />,
      },
      {
        path: webRoutes.allCoursesCatogories,
        element: <AllCourseCategories />,
      },
      {
        path: webRoutes.allInstituteList,
        element: <AllInstituteList />,
      },
    ],
  },

  {
    element: (
      <AuthInit>
        <WebsiteLayout />
        <ScrollToTop />
      </AuthInit>
    ),
    children: [
      {
        path: webRoutes.userProfile,
        element: (
          <section className="container">
            <UserProfile />
          </section>
        ),
      },
      {
        path: webRoutes.authCourseDetails,
        element: <AuthCourseLearnIndex />,
      },
    ],
  },
  {
    element: (
      <AuthInit>
        <DashboardLayout />
      </AuthInit>
    ),
    children: [
      {
        path: webRoutes.myProfile,
        element: <MyProfile />,
      },
      {
        path: webRoutes.dashboard,
        element: <DashboardPage />,
      },
      {
        path: webRoutes.studentLists,
        element: <StudentList />,
      },
      {
        path: webRoutes.studentProfile,
        element: <StudentProfile />,
      },
      {
        path: webRoutes.counsellorLists,
        element: <CounsellorLists />,
      },
      {
        path: webRoutes.counsellorStudentLists,
        element: <CounsellorStudentList />,
      },
      {
        path: webRoutes.agentLists,
        element: <AgentList />,
      },
      {
        path: webRoutes.agentStudentLists,
        element: <AgentStudentList />,
      },
      {
        path: webRoutes.degreesLists,
        element: <DegreesList />,
      },
      {
        path: webRoutes.countriesList,
        element: <CountriesList />,
      },
      {
        path: webRoutes.coursesList,
        element: <CoursesList />,
      },
      {
        path: webRoutes.courseCategoriesList,
        element: <CourseCategoriesList />,
      },
      {
        path: webRoutes.institutesList,
        element: <InstitutesList />,
      },
      {
        path: webRoutes.institutesList,
        element: <InstitutesList />,
      },
      {
        path: webRoutes.documentslist,
        element: <DocumentsList />,
      },
      {
        path: webRoutes.generalInquires,
        element: <GeneralInquiries />,
      },
      {
        path: webRoutes.applicationStatus,
        element: <ApplicationStatus />,
      },
    ],
  },
]);
