import { Modal } from "antd";
import React from "react";
import RegisterComponent from "../../../components/auth/RegisterComponent";

export default function CreateStudentModal(props) {
  const { isModalOpen, handleCancel, fetchUserIfNecessary, mode } = props;

  return (
    <Modal
      title={`Add ${mode}`}
      open={isModalOpen}
      footer={false}
      onCancel={handleCancel}
    >
      <RegisterComponent onCancel={handleCancel} fetchUserIfNecessary={fetchUserIfNecessary} mode={mode} />
    </Modal>
  );
}
