export const webRoutes = {
  home: "/",
  login: "/login",
  register: "/register",
  logout: "/logout",
  passwordReset: "/password-reset-confirm",
  dashboard: "/dashboard",
  users: "/users",
  about: "/about",
  searchCourses: "/search-courses",
  courseDetails: "/course-details",
  userProfile: "/profile",
  searchCoursesLearn: "/courses",
  allCoursesCatogories: "/all-course-categories",
  allInstituteList: "/all-institute-list",

  createApplication: '/create-application',
  studentLists: '/student-lists',
  studentProfile: '/student-profile',
  myProfile: '/my-profile',

  counsellorLists: '/counsellor-lists',
  counsellorStudentLists: '/counsellor-lists/:id',

  agentLists: '/agent-lists',
  agentStudentLists: '/agent-lists/:id',

  degreesLists: '/degrees',
  countriesList: '/countries-list',
  coursesList: '/courses-list',
  institutesList: '/institutes-list',
  courseCategoriesList: '/course-categories-list',
  documentslist: '/documents-list',
  authCourseDetails: '/learn/auth-course-details',
  generalInquires: '/general-inquiries-list',
  applicationStatus: '/application-status',
  forgetPassword: '/forget-password',
};
