import React, { useEffect, useRef, useState } from "react";

import "swiper/css";
import "swiper/css/navigation";

import {
  faBookOpen,
  faChevronLeft,
  faChevronRight,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Carousel, Space } from "antd";
import publicStore from "../../../stores/publicStore";
import { Link, useNavigate } from "react-router-dom";
import { webRoutes } from "../../../routes/web";

export default function InstituteList() {
  const { institutes, fetchInstitutes } = publicStore();

  const [number, setNumber] = useState(window.innerWidth < 768 ? 1 : 4);
  const navigate = useNavigate();

  const params = new URLSearchParams();
  params.append("is_active", true);
  params.append("order", "title");

  let sliderRef = useRef(null);

  useEffect(() => {
    fetchInstitutes(params.toString());
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 1023 && screenWidth > 768) {
        setNumber(2);
      } else if (screenWidth < 768) {
        setNumber(1);
      } else {
        setNumber(4);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const submitSearch = (id) => {
    const formValue = { institute: id };
    return navigate(webRoutes.searchCourses, { state: formValue });
  };

  return (
    <section>
      <div className="container">
        <div className="flex justify-between items-center mb-5">
          <h2>All Institutes</h2>

          <div className="flex gap-5">
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="cursor-pointer fa-lg"
              onClick={() => {
                sliderRef.current.prev();
              }}
            />
            <FontAwesomeIcon
              icon={faChevronRight}
              className="cursor-pointer fa-lg"
              onClick={() => {
                sliderRef.current.next();
              }}
            />
          </div>
        </div>

        <Carousel slidesToShow={number} autoplay ref={sliderRef} dots={false}>
          {institutes?.results?.map((institute) => {
            return (
              <Space className="h-100"  style={{ minHeight: "380px" }}>
                <div
                  className="rounded-lg border shadow-lg mr-2 p-4 h-100"
                  style={{ minHeight: "280px" }}
                >
                  <p className="mb-3 font-semibold truncate-text">
                    {institute.title}
                  </p>
                  <img
                    src={institute.logo}
                    alt=""
                    className="rounded-xl mb-3 w-full"
                    style={{ height: "160px" }}
                  />
                  <div className="flex items-top mb-2">
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      className="mr-3 mt-2"
                    />
                    <p>{institute.address}</p>
                  </div>
                  <div>
                    <p className="mb-0">
                      <div
                        onClick={() => submitSearch(institute.id)}
                        className="cursor-pointer"
                      >
                        <FontAwesomeIcon icon={faBookOpen} className="mr-2" />
                        View Courses
                      </div>
                    </p>
                  </div>
                </div>
              </Space>
            );
          })}
        </Carousel>
        <div className="text-center mt-5 font-bold text-theme-blue">
          <Link to={webRoutes.allInstituteList}>View All Institute</Link>
        </div>
      </div>
    </section>
  );
}
