import { React, useEffect, useState } from "react";
import coverimg from "../../../assets/images/collections/18.png";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";
import CourseCategories from "./courseCategories";
import InstituteList from "./instituteList";
import { webRoutes } from "../../../routes/web";
import publicStore from "../../../stores/publicStore";
import GeneralInquiries from "../generalInquiries/GeneralInquiries";

export default function Home() {
  const navigate = useNavigate();
  publicStore();
  const {
    countries,
    fetchCountries,
    degrees,
    fetchDegrees,
    courseCategories,
    fetchCourseCategories,
  } = publicStore();
  const [formValue, setFormValue] = useState({});

  const params = new URLSearchParams();
  params.append("is_active", true);
  params.append("order", "name");
  params.append("limit", "200");

  const styles = {
    hero: {
      backgroundImage: `url(${coverimg})`,
      backgroundSize: "cover",
      backgroundPosition: 'center'
    },
  };

  useEffect(() => {
    fetchCountries(params.toString());
    fetchDegrees(params.toString());
    fetchCourseCategories(params.toString());
  }, []);


  const getCountries = () => {
    return countries.map((country) => ({
      label: country.name,
      value: country.id,
    }));
  };

  const getDegrees = () => {
    return degrees?.results?.map((degree) => ({
      label: degree.name,
      value: degree.id,
    }));
  };

  const getCourseCategories = () => {
    return courseCategories?.results?.map((course) => ({
      label: course.name,
      value: course.id,
    }));
  };

  const handleChange = (name, value) => {
    setFormValue({ ...formValue, [name]: value });
  };

  const submitSearch = () => {
    return navigate(webRoutes.searchCourses, { state: formValue });
  };


  return (
    <div>
      <div className="h-[60vh] lg:h-[75vh]">
        <div className="hero max-h-[55vh] lg:max-h-[65vh]" style={styles.hero}>
          <div className="container">
            <div className="flex justify-center min-h-screen items-center">
              <div className="bg-white p-6 lg:p-10 w-full rounded-lg shadow-lg -mt-40 lg:mt-60">
                <h3 className="mb-5">
                  Search Courses
                </h3>
                <div className="grid gap-4 lg:gap-10 lg:grid-cols-3 items-center lg:justify-between">
                  <Select
                    showSearch
                    allowClear
                    placeholder="Select country"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "").includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={getCountries()}
                    onChange={(id) => handleChange("country", id)}
                    value={formValue.country}
                    className="w-full min-h-[45px]"
                  />
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select degree"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "").includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={getDegrees()}
                    onChange={(id) => handleChange("degree", id)}
                    value={formValue.degree}
                    className="w-full min-h-[45px]"
                  />
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select course"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "").includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={getCourseCategories()}
                    onChange={(id) => handleChange("course", id)}
                    value={formValue.course}
                    className="w-full min-h-[45px]"
                  />
                </div>

                <button
                  type="button"
                  class="default-btn mt-4 w-full lg:w-fit float-right"
                  onClick={submitSearch}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CourseCategories />
      <InstituteList />
      <GeneralInquiries />
    </div>
  );
}
