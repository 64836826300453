import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import publicStore from "../../../stores/publicStore";
import { webRoutes } from "../../../routes/web";

export default function CourseCategories() {
  const navigate = useNavigate();

  const params = new URLSearchParams();
  params.append("is_active", true);
  params.append("order", "name");

  const { courseCategories } = publicStore();

  useEffect(() => {
    // fetchCourseCategories(params.toString());
  }, []);

  const submitSearch = (id) => {
    const formValue = { course: id };
    return navigate(webRoutes.searchCourses, { state: formValue });
  };

  return (
    <section>
      <div className="container">
        <div className="lg:flex justify-between items-center mb-5">
          <h2>Top Course Categories</h2>
          <Link to={webRoutes.allCoursesCatogories} className="text-sm hover:underline text-theme-primary">
            Explore All
          </Link>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-5">
          {courseCategories?.results?.map((course, index) => {
            return (
              index < 12 && (
                <div className="border rounded-lg shadow-md p-4 text-center cursor-pointer hover:scale-105 hover:shadow-xl" onClick={() => submitSearch(course.id)}>
                  <div className="course-category-block d-flex justify-center items-center rounded-full h-[84px] w-[84px] m-auto mb-3">
                    <img
                      src={course.icon}
                      alt={course.name + 'edudoko icon'}
                      className="m-auto p-4 object-cover h-full w-full"
                      style={{ filter: "invert(100%)" }}
                    />
                  </div>
                  <h4>{course.name}</h4>
                </div>
              )
            );
          })}
        </div>
      </div>
    </section>
  );
}
