import { RouterProvider } from "react-router-dom";
import { browserRouter } from "./routes/browserRouter";
import { ConfigProvider } from "antd";
import { AuthProvider } from "./auth/Auth";
import ScrollToTop from "./utils/ScrollToTop";
import { Suspense } from "react";
import loaderStore from "./stores/loaderStore";

function App() {
  const { loaderStatus } = loaderStore();

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: "#0874e7",
            defaultHoverBg: "#000000",
          },
        },
      }}
    >
      <AuthProvider>
        <Suspense fallback={<div className="cm-loader">Loading... </div>}>
          {loaderStatus && (
            <div className="cm-loader-wrapper">
              <div className="block">
                <div class="loader">
                  <div class="circle"></div>
                  <div class="circle"></div>
                  <div class="circle"></div>
                  <div class="circle"></div>
                </div>
                <p className="text-black mt-6">please wait...</p>
              </div>
            </div>
          )}
          <RouterProvider router={browserRouter} />
        </Suspense>
      </AuthProvider>
    </ConfigProvider>
  );
}

export default App;
