import {
  faBars,
  faCircleUser,
  faRightFromBracket,
  faSquarePollHorizontal,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AutoComplete, Button, Dropdown, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../auth/Auth";
import { webRoutes } from "../../routes/web";
import publicStore from "../../stores/publicStore";
import AsideNavbarDrawer from "./asideNavbarDrawer";
import { GetUserRole } from "../../utils/helper";
import { Constant } from "../../utils/constant";

export default function WebsiteNavbar() {
  const { logout, currentUser } = useAuth();

  const navigate = useNavigate();
  const [formValue, setFormValue] = useState({});

  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };

  const {
    countries,
    fetchCountries,
    degrees,
    fetchDegrees,
    courseCategories,
    fetchCourseCategories,
  } = publicStore();

  let items;

  GetUserRole() === Constant.student
    ? (items = [
      {
        key: "1",
        label: (
          <Link to={webRoutes.userProfile}>
            <FontAwesomeIcon icon={faUser} className="mr-3" />
            Profile
          </Link>
        ),
      },
      {
        key: "2",
        label: (
          <div onClick={() => logout()}>
            <FontAwesomeIcon icon={faRightFromBracket} className="mr-3" />
            Log out
          </div>
        ),
      },
    ])
    : (items = [
      {
        key: "1",
        label: (
          <Link to={webRoutes.dashboard}>
            <FontAwesomeIcon icon={faSquarePollHorizontal} className="mr-3" />
            Dashboard
          </Link>
        ),
      },
      {
        key: "2",
        label: (
          <div onClick={() => logout()}>
            <FontAwesomeIcon icon={faRightFromBracket} className="mr-3" />
            Log out
          </div>
        ),
      },
    ]);

  const params = new URLSearchParams();
  params.append("is_active", true);
  params.append("order", "name");
  params.append("limit", "200");

  useEffect(() => {
    fetchCountries(params.toString());
    fetchDegrees(params.toString());
    fetchCourseCategories(params.toString());
  }, []);

  const getCountries = () => {
    return countries.map((country) => ({
      label: country.name,
      value: country.id,
    }));
  };

  const getDegrees = () => {
    return degrees?.results?.map((degree) => ({
      label: degree.name,
      value: degree.id,
    }));
  };

  const getCourseCategories = () => {
    return courseCategories?.results?.map((course) => ({
      label: course.name,
      value: course.id,
    }));
  };

  const handleChange = (name, value) => {
    setFormValue({ ...formValue, [name]: value });
  };

  const submitSearch = () => {
    return navigate(webRoutes.searchCourses, { state: formValue });
  };

  return (
    <div className="top-navbar fixed w-full z-100 p-5">
      <div className="lg:flex items-center justify-between">
        <div className="flex justify-between items-center mb-4 lg:mb-0">
          <Link to={"/"}>
            <h3 className="">EDUDOKO</h3>
          </Link>
          <div className="flex items-center lg:hidden">
            {currentUser && (
              <Dropdown
                menu={{
                  items,
                }}
                placement="bottomRight"
                className="cursor-pointer"
              >
                <FontAwesomeIcon icon={faCircleUser} className="fa-2xl mr-5" />
              </Dropdown>
            )}
            <FontAwesomeIcon
              icon={faBars}
              className="fa-xl cursor-pointer"
              onClick={() => setOpen(true)}
            />
          </div>
        </div>

        <div className="flex items-center hidden lg:block">
          {currentUser && (
            <Dropdown
              menu={{
                items,
              }}
              placement="bottomRight"
              className="cursor-pointer"
            >
              <FontAwesomeIcon icon={faCircleUser} className="fa-2xl mr-5" />
            </Dropdown>
          )}
          <FontAwesomeIcon
            icon={faBars}
            className="fa-xl cursor-pointer"
            onClick={() => setOpen(true)}
          />
        </div>
      </div>

      <AsideNavbarDrawer open={open} onClose={onClose} />
    </div>
  );
}
