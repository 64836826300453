// store.js
import { create } from "zustand";
import axiosInstance from "../routes/axiosInstance";
import { apiList } from "../routes/api";
import { AuthRefreshToken, getUserToken } from "../utils/helper";

const publicStore = create((set) => ({
  countries: [],
  degrees: [],
  courses: [],
  courseCategories: [],
  institutes: [],
  courseDetails: {},
  videoCoursesList: [],
  videoCourseLession: [],
  courseInfo: [],
  applicationStatuses: [],

  fetchCountries: async (params) => {
    const response = await axiosInstance.get(apiList.countries + "?" + params);
    if (response.status === 200) {
      set({ countries: response.data.results });
    }
  },

  fetchDegrees: async (params) => {
    const response = await axiosInstance.get(apiList.degrees + "?" + params);
    if (response.status === 200) {
      set({ degrees: response.data });
    }
  },

  fetchCourseCategories: async (params) => {
    const response = await axiosInstance.get(
      apiList.courseCategories + "?" + params
    );
    if (response.status === 200) {
      set({ courseCategories: response.data });
    }
  },

  fetchInstitutes: async (params) => {
    const response = await axiosInstance.get(apiList.institutes + "?" + params);
    if (response.status === 200) {
      set({ institutes: response.data });
    }
  },

  fetchCourses: async (params) => {
    const response = await axiosInstance.get(apiList.courses + "?" + params);
    if (response.status === 200) {
      set({ courses: response.data });
    }
  },

  fetchCourseDetails: async (params) => {
    const response = await axiosInstance.get(apiList.courses + params);
    if (response.status === 200) {
      set({ courseDetails: response.data });
    }
  },

  fetchApplicationStatuses: async () => {
    const response = await axiosInstance.get(apiList.applicationStatuses);
    if (response.status === 200) {
      set({ applicationStatuses: response.data });
    }
  },

  general_inquiries_create: async (data) => {
    await axiosInstance.post(
      apiList.general_inquiries_create,
      data
    );
  },
}));

export default publicStore;
