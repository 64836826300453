import {
  faEye,
  faPenToSquare,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Input, Modal, Popconfirm, Table, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { webRoutes } from "../../../routes/web";
import studentStore from "../../../stores/studentStore";
// import CreateStudentModal from "./CreateStudentModal";
import BasicPagination from "../../../components/BasicPagination";
import EditRegisterComponent from "../../../components/auth/EditRegisterComponent";
import Permission from "../../../components/auth/Permission";
import authStore from "../../../stores/authStore";
import Debounce from "../../../utils/Debounce";
import { Constant } from "../../../utils/constant";
import { GetUserRole, toastSuccess } from "../../../utils/helper";
import CreateStudentModal from "../student/CreateStudentModal";
import loaderStore from "../../../stores/loaderStore";
import moment from "moment";

export default function CounsellorLists() {
  const { studentLists, getStudentList } = studentStore();
  const { showLoader, hideLoader } = loaderStore();

  const { deleteUser } = authStore();

  const navigate = useNavigate();

  const [offset, setoffset] = useState(0);
  const [limit, setlimit] = useState(20);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [formData, setformData] = useState({});
  const [isEditModalOpen, setisEditModalOpen] = useState(false);
  const [isModalOpen, setisModalOpen] = useState(false);
  const handleEditModalCancel = () => setisEditModalOpen(false);
  const debouncedSearchTerm = Debounce(search, 500);

  const mode = Constant.counsellor;

  useEffect(() => {
    getCounsellorList();
  }, [limit, offset, debouncedSearchTerm]);

  const getCounsellorList = () => {
    const params = new URLSearchParams();
    params.append("offset", offset);
    params.append("limit", limit);
    params.append("search", search);
    params.append("order", "-updated_at");

    if (
      GetUserRole() === Constant.admin ||
      GetUserRole() === Constant.super_admin
    ) {
      params.append("role", "counsellor");
    }
    getStudentList(params.toString()).finally(() => {
      setLoading(false);
    });
  };

  const setDefaultCurrent = (val) => setoffset(val * limit - limit);
  const setpageSizeOptions = (val) => setlimit(val);

  const confirm = (id) => {
    showLoader();
    deleteUser(id)
      .then(() => {
        toastSuccess("User has been deleted successfully!");
        getCounsellorList();
      })
      .catch(() => {})
      .finally(() => hideLoader());
  };

  const edit = (formData) => {
    setformData(formData);
    setisEditModalOpen(true);
  };

  const cancel = (e) => {};

  const columns = [
    {
      title: "Name",
      dataIndex: ["first_name", "last_name"],
      key: "name",
      render: (text, record) => (
        <span>
          {record.first_name} {record.last_name}
        </span>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone No.",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Consultancy Name",
      dataIndex: "consultancy",
      key: "consultancy",
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (text, record) => (
        <span>
          {record.is_active ? (
            <Tag color="green">Active</Tag>
          ) : (
            <Tag color="red">Inactive</Tag>
          )}
        </span>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "date_joined",
      key: "date_joined",
      render: (text, record) => (
        <span>
          {moment(record.date_joined).format("YYYY-MM-DD")}
        </span>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      fixed: "right",
      render: (text, record) => (
        <div className="flex gap-3">
          <Tooltip title="View details">
            <div
              className="py-1 px-2 text-slate-500 bg-blue-100 rounded-md cursor-pointer hover:bg-blue-200 hover:text-slate-600 hover:shadow"
              onClick={() =>
                navigate(webRoutes.counsellorLists + "/" + record.id)
              }
            >
              <FontAwesomeIcon icon={faEye} />
            </div>
          </Tooltip>

          <Tooltip title="Edit User">
            <div
              className="py-1 px-2 text-slate-500 bg-amber-100 rounded-md cursor-pointer hover:bg-amber-200 hover:text-slate-600 hover:shadow"
              onClick={() => edit(record)}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </div>
          </Tooltip>

          <Permission allowedRoles={[Constant.admin, Constant.super_admin]}>
            <Tooltip title="Delete user">
              <Popconfirm
                placement="topRight"
                title="Delete user"
                description="Are you sure to delete this user?"
                onConfirm={() => confirm(record.id)}
                onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <div className="py-1 px-2 text-red-500 bg-red-100 rounded-md cursor-pointer hover:bg-red-200 hover:text-red-600 hover:shadow">
                  <FontAwesomeIcon icon={faTrashCan} />
                </div>
              </Popconfirm>
            </Tooltip>
          </Permission>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="flex justify-between items-center mb-5">
        <h3>All Counsellors</h3>
        <div className="flex items-center gap-5">
          <div>
            <Input
              placeholder="Search counsellor"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </div>
          <Button type="primary" onClick={() => setisModalOpen(true)}>
            Add counsellor
          </Button>
        </div>

        <CreateStudentModal
          isModalOpen={isModalOpen}
          handleCancel={() => setisModalOpen(false)}
          fetchUserIfNecessary={() => getCounsellorList()}
          mode="counsellor"
        />

        <Modal
          title={mode + ' Update'}
          open={isEditModalOpen}
          footer={false}
          onCancel={handleEditModalCancel}
        >
          {Object.keys(formData).length > 0 && (
            <EditRegisterComponent
              onCancel={handleEditModalCancel}
              mode={mode}
              formData={formData}
              fetchUserIfNecessary={() => getCounsellorList()}
            />
          )}
        </Modal>
      </div>

      <Table
        bordered
        size="small"
        columns={columns}
        dataSource={studentLists.results}
        loading={loading}
        pagination={false}
        scroll={{ x: "max-content" }}
        className="drop-shadow-md"
      />

      <BasicPagination
        total={studentLists.count}
        limit={limit}
        defaultCurrent={setDefaultCurrent}
        pageSizeOptions={setpageSizeOptions}
      />
    </div>
  );
}
