import { create } from "zustand";

const loaderStore = create((set) => ({
  loaderStatus: false,

  showLoader: async () => {
    set({ loaderStatus: true });
  },

  hideLoader: async () => {
    setTimeout(() => {
      set({ loaderStatus: false });
    }, 500);
  },
}));

export default loaderStore;
