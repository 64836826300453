// store.js
import { create } from "zustand";
import { apiList } from "../routes/api";
import axiosInstance from "../routes/axiosInstance";
import { Constant } from "../utils/constant";
import { AuthRefreshToken, getUserToken } from "../utils/helper";

let url;
const adminStore = create((set) => ({
  degrees: [],
  countries: [],
  courses: [],
  courseCategories: [],
  institutes: [],
  academicDocumentList: [],
  videoCourseCategories: [],
  videoCoursesList: [],
  videoCourseLessonsList: [],
  generalInquiriesList: [],

  fetchDocuments: async (params) => {
    await AuthRefreshToken();
    const response = await axiosInstance.get(
      apiList.academicDocumentList + "?" + params,
      {
        headers: {
          Authorization: "Bearer " + getUserToken(),
        },
      }
    );
    if (response.status === 200) {
      set({ academicDocumentList: response.data });
    }
  },

  fetchDegrees: async (params) => {
    await AuthRefreshToken();
    const response = await axiosInstance.get(
      apiList.degreesLists + "?" + params,
      {
        headers: {
          Authorization: "Bearer " + getUserToken(),
        },
      }
    );
    if (response.status === 200) {
      set({ degrees: response.data });
    }
  },

  fetchCountries: async (params) => {
    await AuthRefreshToken();
    const response = await axiosInstance.get(
      apiList.countriesList + "?" + params,
      {
        headers: {
          Authorization: "Bearer " + getUserToken(),
        },
      }
    );
    if (response.status === 200) {
      set({ countries: response.data });
    }
  },

  fetchCourses: async (params) => {
    await AuthRefreshToken();
    const response = await axiosInstance.get(
      apiList.coursesList + "?" + params,
      {
        headers: {
          Authorization: "Bearer " + getUserToken(),
        },
      }
    );
    if (response.status === 200) {
      set({ courses: response.data });
    }
  },

  fetchInstitute: async (params) => {
    await AuthRefreshToken();
    const response = await axiosInstance.get(
      apiList.institutesList + "?" + params,
      {
        headers: {
          Authorization: "Bearer " + getUserToken(),
        },
      }
    );
    if (response.status === 200) {
      set({ institutes: response.data });
    }
  },

  fetchCourseCategories: async (params) => {
    await AuthRefreshToken();
    const response = await axiosInstance.get(
      apiList.courseCategoriesList + "?" + params,
      {
        headers: {
          Authorization: "Bearer " + getUserToken(),
        },
      }
    );
    if (response.status === 200) {
      set({ courseCategories: response.data });
    }
  },

  postModalStore: async (params, mode) => {
    await AuthRefreshToken();
    let additionalHeaders;
    if (mode) {
      switch (mode) {
        case Constant.degrees:
          url = apiList.degreesLists;
          break;

        case Constant.countries:
          url = apiList.countriesList;
          break;

        case Constant.courses:
          url = apiList.coursesList;
          break;

        case Constant.documents:
          url = apiList.academicDocumentList;
          break;

        case Constant.coursesCategories:
          url = apiList.courseCategoriesList;
          additionalHeaders = { "Content-type": "multipart/form-data" };
          break;

        case Constant.institutes:
          url = apiList.institutesList;
          additionalHeaders = { "Content-type": "multipart/form-data" };
          break;

        case Constant.videoCourseCategories:
          url = apiList.videoCourseCategoriesList;
          break;

        case Constant.videoCourses:
          url = apiList.adminVideoCoursesList;
          break;

        case Constant.videoCourseLessionList:
          url = apiList.adminVideoCourseLessonsList;
          break;

        case Constant.application_status:
          url = apiList.postApplicationStatuses;
          break;

        case Constant.video_course_lessons:
          url = apiList.adminVideoCourseLessonsList;
          break;

        default:
          url = apiList.adminCreateUser;
          break;
      }
    }
    const response = await axiosInstance.post(url, params, {
      headers: {
        Authorization: "Bearer " + getUserToken(),
        ...additionalHeaders,
      },
    });
    if (response.status === 201) {
      return { status: 200 };
    } else {
      return new Error("API Error");
    }
  },

  updateModalStore: async (params, mode) => {
    let additionalHeaders;
    await AuthRefreshToken();
    if (mode) {
      switch (mode) {
        case Constant.degrees:
          url = apiList.degreesLists;
          break;

        case Constant.countries:
          url = apiList.countriesList;
          break;

        case Constant.courses:
          url = apiList.coursesList;
          break;

        case Constant.documents:
          url = apiList.academicDocumentList;
          break;

        case Constant.coursesCategories:
          url = apiList.courseCategoriesList;
          additionalHeaders = { "Content-type": "multipart/form-data" };
          break;

        case Constant.institutes:
          url = apiList.institutesList;
          additionalHeaders = { "Content-type": "multipart/form-data" };
          break;

        case Constant.videoCourseCategories:
          url = apiList.videoCourseCategoriesList;
          break;

        case Constant.videoCourses:
          url = apiList.adminVideoCoursesList;
          break;

        case Constant.videoCourseLessionList:
          url = apiList.adminVideoCourseLessonsList;
          break;

        case Constant.application_status:
          url = apiList.postApplicationStatuses;
          break;

        case Constant.video_course_lessons:
          url = apiList.adminVideoCourseLessonsList;
          break;

        default:
          url = apiList.adminCreateUser;
          break;
      }
    }
    const response = await axiosInstance.patch(url + params.id, params.data, {
      headers: {
        Authorization: "Bearer " + getUserToken(),
        ...additionalHeaders,
      },
    });
    if (response.status === 201 || response.status === 200) {
      return { status: 200 };
    } else {
      return new Error("API Error");
    }
  },

  deleteModalStore: async (params, mode) => {
    await AuthRefreshToken();
    if (mode) {
      switch (mode) {
        case Constant.degrees:
          url = apiList.degreesLists;
          break;

        case Constant.countries:
          url = apiList.countriesList;
          break;

        case Constant.courses:
          url = apiList.coursesList;
          break;

        case Constant.coursesCategories:
          url = apiList.courseCategoriesList;
          break;

        case Constant.institutes:
          url = apiList.institutesList;
          break;

        case Constant.documents:
          url = apiList.academicDocumentList;
          break;

        case Constant.videoCourseCategories:
          url = apiList.videoCourseCategoriesList;
          break;

        case Constant.videoCourses:
          url = apiList.adminVideoCoursesList;
          break;

        case Constant.videoCourseLessionList:
          url = apiList.adminVideoCourseLessonsList;
          break;

        case Constant.application_status:
          url = apiList.postApplicationStatuses;
          break;

        case Constant.video_course_lessons:
          url = apiList.adminVideoCourseLessonsList;
          break;

        default:
          url = apiList.adminCreateUser;
          break;
      }
    }
    const response = await axiosInstance.delete(url + params, {
      headers: {
        Authorization: "Bearer " + getUserToken(),
      },
    });
    if (response.status === 201) {
      return { status: 200 };
    } else {
      return new Error("API Error");
    }
  },

  uploadCSVCourses: async (params) => {
    await AuthRefreshToken();
    const response = await axiosInstance.post(
      apiList.uploadCSVCourses,
      params,
      {
        headers: {
          Authorization: "Bearer " + getUserToken(),
          "Content-type": "multipart/form-data",
        },
      }
    );
    if (response.status === 200) {
      return response.data;
      // set({ degrees: response.data });
    } else {
      return new Error("API Error");
    }
  },

  fetchAllGeneralInquiriesList: async (id) => {
    await AuthRefreshToken();
    const response = await axiosInstance.get(apiList.adminGeneralInquiries, {
      headers: {
        Authorization: "Bearer " + getUserToken(),
      },
    });
    if (response.status === 200) {
      set({ generalInquiriesList: response.data });
    }
  },

  deleteGeneralInquirie: async (id) => {
    await AuthRefreshToken();
    await axiosInstance.delete(apiList.adminGeneralInquiries + id, {
      headers: {
        Authorization: "Bearer " + getUserToken(),
      },
    });
  },

  fetchAllProgressStatus: async (params) => {
    await AuthRefreshToken();
    const response = await axiosInstance.get(
      apiList.applicationStatuses + "?" + params,
      {
        headers: {
          Authorization: "Bearer " + getUserToken(),
        },
      }
    );
    if (response.status === 200) {
      set({ progressStatus: response.data });
    }
  },

  createProgressStatus: async (params) => {
    await AuthRefreshToken();
    const response = await axiosInstance.post(
      apiList.applicationStatuses,
      params,
      {
        headers: {
          Authorization: "Bearer " + getUserToken(),
        },
      }
    );
    if (response.status === 200) {
      console.log("response: ", response);
    }
  },
}));

export default adminStore;
