import React from "react";
import RegisterComponent from "../../components/auth/RegisterComponent";

export default function RegisterPage() {
  return (
    <section className="h-full lg:h-screen flex items-center justify-center">
      <div className="container">
        <RegisterComponent mode="student" isSignup="true" />
      </div>
    </section>
  );
}
