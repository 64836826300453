import Cookies from "js-cookie";
import { CryptoJSObjectDecrypt, CryptoJSObjectEncrypt } from "../utils/helper";

const AUTH_LOCAL_STORAGE_KEY = "astd-auth";
const AUTH_LOCAL_ACCESS_TOKEN = "astd-auth-token";

const setAuth = (auth) => {
  try {
    const lsValue = CryptoJSObjectEncrypt(auth);

    // Set the expiration time to 6 hours from the current date and time
    const expiresInHours = 6;
    const now = new Date();
    const expires = new Date(now.getTime() + expiresInHours * 60 * 60 * 1000);
    Cookies.set(AUTH_LOCAL_STORAGE_KEY, lsValue, { expires: expires });
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const getAuth = () => {
  const lsValue = Cookies.get(AUTH_LOCAL_STORAGE_KEY);
  if (!lsValue) {
    removeAuth();
    return;
  }

  try {
    const auth = CryptoJSObjectDecrypt(lsValue);
    if (auth) {
      return auth;
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
};

const removeAuth = () => {
  try {
    Cookies.remove(AUTH_LOCAL_STORAGE_KEY);
    Cookies.remove(AUTH_LOCAL_ACCESS_TOKEN);
    // window.location.reload();
  } catch (error) {
    console.error("AUTH LOCAL STORAGE REMOVE ERROR", error);
  }
};

export { getAuth, removeAuth, setAuth };
